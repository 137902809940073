import { BN, IdlAccounts, Program } from '@coral-xyz/anchor';
import {
    Connection,
    Keypair,
    PublicKey,
    SystemProgram,
    Transaction,
} from '@solana/web3.js';
import {
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_2022_PROGRAM_ID,
    getAssociatedTokenAddressSync,
} from '@solana/spl-token';
import { FomoloveGame } from './build/fomolove_game';

export type ConfigData = IdlAccounts<FomoloveGame>['configAccount'];

export type SeasonData = IdlAccounts<FomoloveGame>['seasonAccount'];

export type TeamData = IdlAccounts<FomoloveGame>['teamAccount'];

export type UserData = IdlAccounts<FomoloveGame>['userAccount'];

export type GameData = IdlAccounts<FomoloveGame>['gameAccount'];

export type WinnerData = IdlAccounts<FomoloveGame>['winnerAccount'];

export type UserSeasonData = IdlAccounts<FomoloveGame>["userSeasonAccount"];

export enum ETeamType {
    Meme = 'MemeTeam',
    Chain = 'ChainTeam',
}

export const TeamType = {
    [ETeamType.Meme]: { memeTeam: {} },
    [ETeamType.Chain]: { chainTeam: {} },
};

export const MoveDirection = {
    UP: { up: {} },
    DOWN: { down: {} },
    LEFT: { left: {} },
    RIGHT: { right: {} },
} as const;

export type MoveDirection = (typeof MoveDirection)[keyof typeof MoveDirection];

export class FomoloveGameProgram {
    constructor(
        public readonly idl: FomoloveGame,
        public readonly connection: Connection
    ) { }

    get program() {
        return new Program(this.idl, { connection: this.connection });
    }

    get accounts(): any {
        return this.program.account;
    }

    get configPDA(): PublicKey {
        return PublicKey.findProgramAddressSync(
            [Buffer.from("config")],
            this.program.programId
        )[0];
    }

    get winnerPDA(): PublicKey {
        return PublicKey.findProgramAddressSync(
            [Buffer.from("winner")],
            this.program.programId
        )[0];
    }

    get memeTeamPDA(): PublicKey {
        return PublicKey.findProgramAddressSync(
            [Buffer.from("meme_team")],
            this.program.programId
        )[0];
    }

    get chainTeamPDA(): PublicKey {
        return PublicKey.findProgramAddressSync(
            [Buffer.from("chain_team")],
            this.program.programId
        )[0];
    }

    seasonPDA(seasonId: number): PublicKey {
        const seasonIdBuffer = Buffer.from([seasonId]);

        return PublicKey.findProgramAddressSync(
            [Buffer.from("season"), seasonIdBuffer],
            this.program.programId
        )[0];
    }

    userPDA(userPubkey: PublicKey): PublicKey {
        return PublicKey.findProgramAddressSync(
            [Buffer.from("user"), userPubkey.toBuffer()],
            this.program.programId
        )[0];
    }

    userSeasonPDA(userPubkey: PublicKey, seasonId: number): PublicKey {
        const seasonIdBuffer = Buffer.from([seasonId]);

        return PublicKey.findProgramAddressSync(
            [Buffer.from("user_season"), userPubkey.toBuffer(), seasonIdBuffer],
            this.program.programId
        )[0];
    }

    gamePDA(nftMintPubkey: PublicKey): PublicKey {
        return PublicKey.findProgramAddressSync(
            [Buffer.from("game"), nftMintPubkey.toBuffer()],
            this.program.programId
        )[0]
    }

    associatedNftTokenPDA(userPubkey: PublicKey, nftMintPubkey: PublicKey) {
        return getAssociatedTokenAddressSync(
            nftMintPubkey,
            userPubkey,
            false,
            TOKEN_2022_PROGRAM_ID,
            ASSOCIATED_TOKEN_PROGRAM_ID
        );
    }

    /* ---------------------------------------------------------------INSTRUCTION-------------------------------------------------------------- */
    async initialize(maintainer: PublicKey, memeTeamBaseUrl: string, chainTeamBaseUrl: string): Promise<Transaction> {
        const tx = await this.program.methods
            .initialize(chainTeamBaseUrl, memeTeamBaseUrl)
            .accountsPartial({
                maintainer: maintainer,
                configAccount: this.configPDA,
                memeTeamAccount: this.memeTeamPDA,
                chainTeamAccount: this.chainTeamPDA,
                winnerAccount: this.winnerPDA
            })
            .transaction();
        return tx;
    }

    async startSeason(
        maintainer: PublicKey,
        seasonId: number,
        startTime: BN
    ): Promise<Transaction> {
        const tx = await this.program.methods
            .startSeason(startTime)
            .accountsPartial({
                maintainer: maintainer,
                configAccount: this.configPDA,
                seasonAccount: this.seasonPDA(seasonId),
            })
            .transaction();
        return tx;
    }

    async chooseTeam(
        userPubkey: PublicKey,
        teamType: ETeamType
    ): Promise<Transaction> {
        console.log(userPubkey.toString())
        const tx = await this.program.methods
            .chooseTeam(TeamType[teamType])
            .accountsPartial({
                user: userPubkey,
                userAccount: this.userPDA(userPubkey),
                teamMemeAccount: this.memeTeamPDA,
                teamChainAccount: this.chainTeamPDA,
            })
            .transaction();
        return tx;
    }

    async registerGame(
        userPubkey: PublicKey,
        seasonId: number
    ): Promise<{ transaction: Transaction; nftMint: Keypair }> {
        const nftMint = Keypair.generate();

        const destinationTokenAccount = this.associatedNftTokenPDA(
            userPubkey,
            nftMint.publicKey
        );
        const tx = await this.program.methods
            .registerGame()
            .accountsPartial({
                user: userPubkey,
                userAccount: this.userPDA(userPubkey),
                gameAccount: this.gamePDA(nftMint.publicKey),
                seasonAccount: this.seasonPDA(seasonId),
                userSeasonAccount: this.userSeasonPDA(userPubkey, seasonId),
                nftMint: nftMint.publicKey,
                configAccount: this.configPDA,
                tokenAccount: destinationTokenAccount,
                tokenProgram: TOKEN_2022_PROGRAM_ID,
                associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
                systemProgram: SystemProgram.programId,
            })
            .transaction();
        const hash = await this.connection.getLatestBlockhash();
        tx.recentBlockhash = hash.blockhash;
        tx.feePayer = userPubkey;

        tx.partialSign(nftMint);
        return {
            transaction: tx,
            nftMint,
        };
    }

    async makeMove(
        userPubkey: PublicKey,
        nftMintPubkey: PublicKey,
        seasonId: number,
        direction: MoveDirection
    ): Promise<Transaction> {
        const nftTokenAccount = this.associatedNftTokenPDA(
            userPubkey,
            nftMintPubkey
        );
        const userTeamAccount = await this.getUserTeamAccount(userPubkey);

        const tx = await this.program.methods
            .makeMove(direction)
            .accountsPartial({
                user: userPubkey,
                configAccount: this.configPDA,
                userAccount: this.userPDA(userPubkey),
                game: this.gamePDA(nftMintPubkey),
                userTeamAccount: userTeamAccount,
                userSeasonAccount: this.userSeasonPDA(userPubkey, seasonId),
                nftMint: nftMintPubkey,
                nftTokenAccount,
                winnerAccount: this.winnerPDA,
                tokenProgram: TOKEN_2022_PROGRAM_ID,
                systemProgram: SystemProgram.programId
            })
            .transaction();

        return tx;
    }

    async submitToLeaderBoard(
        userPubkey: PublicKey,
        seasonId: number,
        nftMintPubkey: PublicKey
    ): Promise<Transaction> {
        const tx = await this.program.methods
            .submitLeaderboard()
            .accountsPartial({
                user: userPubkey,
                configAccount: this.configPDA,
                userAccount: this.userPDA(userPubkey),
                seasonAccount: this.seasonPDA(seasonId),
                gameAccount: this.gamePDA(nftMintPubkey),
                nftMint: nftMintPubkey,
                systemProgram: SystemProgram.programId,
            })
            .transaction();
        return tx;
    }

    /* ---------------------------------------------------------------ACCOUNT STATE-------------------------------------------------------------- */
    async getConfigState(): Promise<ConfigData> {
        const configPDA = this.configPDA;
        const configData = await this.accounts.configAccount.fetch(configPDA);
        console.log('configData: ', configData)
        return configData;
    }

    async getWinnerState(): Promise<WinnerData> {
        const winnerPDA = this.winnerPDA;
        const winnerData = await this.accounts.winnerAccount.fetch(winnerPDA);
        return winnerData;
    }

    async getSeasonState(seasonId: number): Promise<SeasonData> {
        const seasonPDA = this.seasonPDA(seasonId);
        const seasonData = await this.accounts.seasonAccount.fetch(seasonPDA);
        return seasonData;
    }

    async getMemeTeamState(): Promise<TeamData> {
        const memeTeamPDA = this.memeTeamPDA;
        const memeTeamData = await this.accounts.teamAccount.fetch(memeTeamPDA);
        console.log('memeTeamData: ', memeTeamData)
        return memeTeamData
    }

    async getChainTeamState(): Promise<TeamData> {
        const chainTeamPDA = this.chainTeamPDA;
        const chainTeamData = await this.accounts.teamAccount.fetch(chainTeamPDA);
        console.log('chainTeamData: ', chainTeamData)
        return chainTeamData
    }

    async getUserState(userPubkey: PublicKey): Promise<UserData> {
        const userPDA = this.userPDA(userPubkey);
        const userData = await this.accounts.userAccount.fetch(userPDA);
        return userData;
    }

    async getUserSeasonState(userPubkey: PublicKey, seasonId: number): Promise<UserSeasonData> {
        const userSeasonPDA = this.userSeasonPDA(userPubkey, seasonId);
        const userSeasonData = await this.accounts.userSeasonAccount.fetch(userSeasonPDA);
        console.log('userSeasonData: ', userSeasonData);
        return userSeasonData;
    }

    async getGameState(nftPubkey: PublicKey): Promise<GameData> {
        const gamePDA = this.gamePDA(nftPubkey);
        const gameData = await this.accounts.gameAccount.fetch(gamePDA);
        return gameData;
    }

    async getUserTeamAccount(userPubkey: PublicKey): Promise<PublicKey> {
        const userState = await this.getUserState(userPubkey);
        if (userState.team.chainTeam) {
            return this.chainTeamPDA;
        } else if (userState.team.memeTeam) {
            return this.memeTeamPDA;
        }
        throw new Error('User not choose team');
    }

    async getNumberOfTransaction(): Promise<number> {
        let signatures: any[] = [];
        let options = { limit: 1000 };
        let before;

        // Fetch signatures in batches
        while (true) {
            const fetchedSignatures = await this.connection.getSignaturesForAddress(this.program.programId, { ...options, before });
            if (fetchedSignatures.length === 0) break;

            signatures = signatures.concat(fetchedSignatures);
            before = fetchedSignatures[fetchedSignatures.length - 1].signature;
        }

        return signatures.length
    }
}
