import './App.css';
import { Route, Routes } from "react-router-dom";
import HomePageSonicFrontier from './pages/HomePageSonicFrontier.js';
import PlayPageSonicFrontier from './pages/PlayPageSonicFrontier.js';
import LeaderBoardSonicFrontier from './pages/LeaderBoardSonicFrontier.js';
import { BrowserRouter } from 'react-router-dom';
import React from "react";
import WalletContextProvider from './shared/provider/WalletProvider.js';
import WalletContextProviderFrontier from './shared/provider/WalletProvider.js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';



function App() {
  const queryClient = new QueryClient();

  return (

    <div>
      <QueryClientProvider client={queryClient}>
        <WalletContextProviderFrontier>
          <BrowserRouter>
            {/* <WalletContextProvider> */}
            <Routes>
              <Route path="/" element={<HomePageSonicFrontier />} />
              <Route path="/testnet-play" element={<PlayPageSonicFrontier />} />
              <Route path="/testnet-leaderboard" element={<LeaderBoardSonicFrontier />} />
            </Routes>
            {/* </WalletContextProvider> */}
          </BrowserRouter>
        </WalletContextProviderFrontier>
      </QueryClientProvider>
    </div>
  );
}

export default App;
