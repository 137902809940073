import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ConfigProvider, Spin } from 'antd';
import logo from '../assets/images/logo.png';
import discord from '../assets/images/discord2.png';
import tele from '../assets/images/tele2.png';
import twi from '../assets/images/twi2.png';
import med from '../assets/images/med2.png';
import gitbook from '../assets/images/GitBook.png'
import queue from '../js/queue.js';
import confetti from '../js/confetti.js';
import board_sonic from '../js/board_sonic.js';
import mute from '../assets/images/Mute.png';
import music from '../assets/images/Song.png';
import gold from '../assets/music/gold.mp3';
import song from '../assets/music/bgm1.mp3';
import sonic from '../assets/images/sonic-logo.png';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { MoveDirection } from '../shared/anchor/fomolove-sonic-program-frontier.ts';
import { PublicKey } from '@solana/web3.js';


import {
    eById,
    eByClass,
    addClass,
    removeClass,
    setOnClick,
    directionToDirectionNumber,
} from "../js/utils.js";
import {
    WalletModalProvider,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import dayjs from 'dayjs';
import { prepareTransaction } from '../shared/utils/solana.utils.ts';


const { useAnchorContextFrontier } = require('../shared/provider/AnchorProvider_frontier.tsx');


const React = require('react');





function PlayPageSonic() {
    // const queryClient = new QueryClient();

    const [openNavi, setOpenVavi] = useState(false);
    const navigate = useNavigate();
    const { publicKey, sendTransaction, signTransaction, wallet } = useWallet();
    // const { error, loading, balance } = useAccountBalance();
    const [showModal, setShowModal] = useState(false);
    const [walletPompt, setWalletPompt] = useState(false);
    const [userBitcoinNFT, setUserBitcoinNFT] = useState(0);
    const [totalKey, setTotalKey] = useState(0);
    const [gameCount, setGameCount] = useState(0);
    const [playerID, setPlayerID] = useState(0);
    const [team, setTeam] = useState(0);
    const [fetchError, setFetchError] = useState(false);
    const [newUserPompt, setNewUserPompt] = useState(false);
    const [noEnoughKey, setNoEnoughKey] = useState(false);
    const [noEnoughSui, setNoEnoughSui] = useState(false);
    const [showSoon, setShowSoon] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [mint2048, setMint2048] = useState(false);
    const [hours, setHours] = useState("24")
    const [minutes, setMinutes] = useState("00")
    const [seconds, setSeconds] = useState("00")
    const [isBelongsToTeam, setIsBelongsToTeam] = useState(false);
    const [userState, setUserState] = useState(null);
    const [userTeam, setUserTeam] = useState('')
    const [nftMint, setNftMint] = useState('');
    const [gameBoard, setGameBoard] = useState('');
    const [submitLeaderboard, setSubmitLeaderboard] = useState(false);
    const [seasonId, setSeasonId] = useState(4);
    const [chainTeam, setChainTeam] = useState('');
    const [memeTeam, setMemeTeam] = useState('')
    const [totalTransaction, setTotalTransaction] = useState('200+ Million')
    const [totalUser, setTotalUser] = useState('970k');
    const [showNotEnoughScore, setShowNotEnoughScore] = useState(false)
    const [minScore, setMinScore] = useState(0);
    const [minTile, setMinTile] = useState(0);

    let moves = {};


    let executingMove = false;
    const responseTimes = {
        total: 0,
        count: 0,
        start: null,
    }



    const { season, fomoloveSonicProgram: program, fetchWinnerState } = useAnchorContextFrontier();

    const { connection } = useConnection();


    let xDown = null;
    let yDown = null;
    let activeGameAddress;
    let topTile = 2;
    let games;
    let walletContents = null;


    const checkValidMove = (packedSpaces, directionNumber) => {
        // console.log("checkValidMove", packedSpaces, directionNumber)
        const direction = parseInt(directionNumber)
        let validMove = false;
        for (let i = 0; i < board_sonic.ROWS; ++i) {
            for (let j = 0; j < board_sonic.COLUMNS; ++j) {
                const tile = packedSpaces[i][j];
                const nextI = direction > 1 ? i + (direction === 2 ? -1 : 1) : i;
                const nextJ = direction < 2 ? j + (direction === 0 ? -1 : 1) : j;
                // console.log('nexti',nextI);
                // console.log('nextj',nextJ);
                if (nextI === -1 || nextI >= board_sonic.ROWS) continue;
                if (nextJ === -1 || nextJ >= board_sonic.COLUMNS) continue;
                const nextTile = packedSpaces[nextI][nextJ];
                // console.log(i, j, nextI, nextJ, tile, nextTile)

                // Skip empty tiles.
                if (tile === 0) continue;
                // skip if next tile out of range

                //
                if (parseInt(nextTile) !== 0 && parseInt(tile) !== parseInt(nextTile)) {
                    continue
                }

                validMove = true;
                break;
            }
        }
        return validMove
    }



    const reset = () => (moves = []);




    const execute = async (
        NftPublicKey,
        directionOrQueuedMove,
        team,
        onComplete,
        onError
    ) => {


        if (!season?.seasonId) {
            alert('Season not init');
            return;
        }

        // console.log(executingMove);
        if (executingMove) return;

        executingMove = true;

        if (board_sonic.active().gameOver) {
            onError({ gameOver: true });
            return;
        }

        // console.log('directionOrQueuedMove', directionOrQueuedMove);

        const direction = directionOrQueuedMove.id
            ? directionOrQueuedMove.direction
            : directionOrQueuedMove;


        const directionNumber = directionToDirectionNumber(direction);

        const { packedSpaces } = board_sonic.active();
        // console.log('packedSpaces', packedSpaces);
        // console.log('directionNumber', directionNumber);
        const validMove = checkValidMove(packedSpaces, directionNumber);
        if (!validMove) {
            moves = {};
            executingMove = false;
            alert('Move invalid!');
            return;
        }
        // console.log('validMove',validMove);



        if (responseTimes.count > 0 && (responseTimes.total / responseTimes.count) > 1000) {
            // console.log(directionOrQueuedMove.id)
            if (!directionOrQueuedMove.id) {
                console.log('directionOrQueuedMove', directionOrQueuedMove)
                directionOrQueuedMove = queue.add(direction);
            }

            if (queue.length() > 1) return;
        }
        // console.log(queue.length())


        moves = {};



        if (!publicKey || !program) return;



        // try {

        let directionMove
        if (direction === 'down') {
            directionMove = MoveDirection.DOWN;
        } else if (direction === 'left') {
            directionMove = MoveDirection.LEFT;
        } else if (direction === 'right') {
            directionMove = MoveDirection.RIGHT;
        } else if (direction === 'up') {
            directionMove = MoveDirection.UP;
        }

        let tx = await program.makeMove(publicKey, NftPublicKey, season?.seasonId, directionMove);

        // console.log('tx', tx)
        tx = await prepareTransaction(tx, connection, publicKey);
        const signedTx = await signTransaction(tx);
        // console.log('signedTx', signedTx)
        const sig = connection.sendRawTransaction(signedTx.serialize());

        // console.log('sig', sig)


        let data;
        try {
            responseTimes.start = Date.now();

            data = await sig;
            // console.log('data', data)


            responseTimes.total += Date.now() - responseTimes.start;
            responseTimes.count += 1;
        } catch (e) {
            onError({ error: e.message });
        } finally {
            executingMove = false;
        }


        if (!data) return;

        queue.remove(directionOrQueuedMove);


        let new_game;

        try {

            setTimeout(async () => {
                new_game = await fetchGameState(NftPublicKey);
                // console.log('after move', new_game);

                const new_board = {
                    packed_spaces: new_game.board,
                    top_tile: new_game.topTile,
                    score: new_game.score,
                    team,
                }
                // console.log('new_board', new_board);


                const newBoard = board_sonic.convertInfo(new_board);
                // console.log('newboard', newBoard);



                onComplete(newBoard, direction);

                if (newBoard.gameOver) {
                    onError({ gameOver: true });
                    return;
                }


                // console.log(queue.length())

                if (queue.length() > 0) {
                    const queuedMove = queue.next()
                    execute(
                        queuedMove,
                        onComplete,
                        onError
                    )
                }
            }, 500)
            // new_game = await fetchGameState(NftPublicKey);


            // setGameBoard(game);

        } catch (error) {
            console.log('🚀 ~ fetchGameState ~ error:', error);
            // setIsBelongsToTeam(false);
        }
        // return

    };


    const season_info = () => {
        // console.log('season', season);

        if (!season) return;
        setSeasonId(season.seasonId)

        const now = dayjs().unix();
        const endedAt = season.endedAt.toNumber();
        // console.log('endedat',endedAt);
        const difference = endedAt - now;

        // console.log('endedat', endedAt);
        // console.log('now', now);
        // console.log(difference);
        if (difference <= 0) {
            setHours('00');
            setMinutes('00');
            setSeconds('00');
        } else {
            let hours = Math.floor((difference % (60 * 60 * 24)) / (60 * 60));
            let minutes = Math.floor((difference % (60 * 60)) / 60);
            let seconds = Math.floor(difference % 60);

            hours = `${hours < 10 ? 0 : ''}${hours}`;
            minutes = `${minutes < 10 ? 0 : ''}${minutes}`;
            seconds = `${seconds < 10 ? 0 : ''}${seconds}`;
            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);
        }

        setTimeout(() => season_info(), 1000);
    }


    const detectUser = async () => {
        if (!publicKey || !program) return;

        try {
            const userAccount = await program.getUserState(publicKey);
            if (
                !!userAccount
            ) {
                setIsBelongsToTeam(true);
            }
            userAccount && setUserState(userAccount);
            if (userAccount) {
                // console.log('userAccount', userAccount)
                // setUserTeam(userAccount.team)
                if (userAccount.team.chainTeam) {
                    console.log('yes chainteam');
                    setUserTeam('ChainTeam')
                } else {
                    console.log('yes memeteam');
                    setUserTeam('MemeTeam');
                }
            }
            return userAccount;
        } catch (error) {
            console.log('🚀 ~ detectUser ~ error:', error);
        }
        // setTimeout(() => season_info(), 60000);
    };

    const tx_info = async () => {
        if (program) {
            console.log('hi data')
            try {
                const number = await program.getNumberOfTransaction();
                setTotalTransaction(number.toString());
                console.log('total tx', number.toString());
            } catch (e) {
                console.error('fatch total tx failed', e)
            }
        }
    }

    let memeUsers;
    const fetchMemeTeam = async () => {
        if (program) {
            try {
                let memeTeamAccount = await program.getMemeTeamState();
                memeUsers = memeTeamAccount.numPlayers
                setMemeTeam(memeUsers)

            } catch (e) {
                console.error('fatch meme team failed', e)
            }
        }
    }

    let chainUsers;
    const fetchChainTeam = async () => {
        if (program) {
            try {
                let chainTeamAccount = await program.getChainTeamState();
                chainUsers = chainTeamAccount.numPlayers;
                setChainTeam(chainUsers)
            } catch (e) {
                console.error('fatch chain team failed', e)
            }
        }
    }

    const countTotalUser = () => {
        if (memeUsers && chainUsers) {
            setTotalUser(memeUsers + chainUsers)
        } else {
            setTimeout(() => { countTotalUser() }, 1000)
        }
    }


    useEffect(() => {
        if (publicKey) {
            // console.log('yes detectuser')
            detectUser();

            const queryParams = new URLSearchParams(window.location.search);

            if (queryParams.get('id')) {
                setTimeout(() => { fetchMintState(queryParams.get('id')) }, 200);

            }
        }
        season_info();
        // tx_info();
        // fetchMemeTeam();
        // fetchChainTeam();
        // countTotalUser();
    }, [publicKey, season])





    const handleLogo = () => {
        document.querySelector('.nav_play').classList.remove('selected');
        document.querySelector('.nav_purchase').classList.add('selected');
        navigate('/');
    }

    const handleNaviPurchase = () => {
        document.querySelector('.nav_play').classList.remove('selected');
        document.querySelector('.nav_purchase').classList.add('selected');
        navigate('/');
    }

    const handleNaviPlay = () => {
        window.location.reload()
    }

    const handleNaviLeaderboard = () => {
        document.querySelector('.nav_play').classList.remove('selected');
        document.querySelector('.nav_leaderboard').classList.add('selected');
        navigate('/testnet-leaderboard');
        // setShowSoon(true);
    }


    const handleHome = () => {

    }

    const handleNavi = () => {
        setOpenVavi(true);
    }



    const handleNaviOk = () => {
        setOpenVavi(false);
    }

    const handleDis = () => {
        window.open('https://discord.com/invite/HP6cBgaP9w', '_blank')
    }

    const handleTel = () => {
        window.open('https://t.me/fomoneyofficial', '_blank')
    }

    const handleTwi = () => {
        window.open('https://twitter.com/Fomoney2048', '_blank')
    }

    const handleMed = () => {
        window.open('https://medium.com/@Fomoney2048', '_blank')
    }

    const handleGit = () => {
        window.open('https://fomoney.gitbook.io/fomoney-litepaper', '_blank');
    }


    const fetchMintState = async (nftMintPublickey) => {
        // console.log('nftMintPublickey',typeof(nftMintPublickey));
        setIsLoading(false)
        setTimeout(() => {
            setNftMint(nftMintPublickey);
        }, 800);

        if (typeof (nftMintPublickey) === 'string') {
            // console.log('string');
            nftMintPublickey = new PublicKey(nftMintPublickey)
        }

        if (!program || !nftMintPublickey) return;

        try {
            const game_board = await program.getGameState(nftMintPublickey);


            setTimeout(() => {
                setGameBoard(game_board.board);
            }, 800);

            console.log('game_board', game_board)
            if (game_board) {
                document.querySelector('.mask').classList.add('hidden');
                const game_publicKey = nftMintPublickey;
                const score = game_board.score;
                const topTile = game_board.topTile;
                const board_arrary = game_board.board;
                if (topTile>=2048){
                    setMint2048(true);
                    return;
                }

                const game_data = {
                    address: game_publicKey,
                    board: {
                        score,
                        packed_spaces: board_arrary,
                        team: userTeam,
                        game_over: false,
                        topTile,
                    },
                };

                // console.log(game_data);
                setActiveGame(game_data);

            }
        } catch (e) {
            setFetchError(true);
            console.error('🚀 ~ fetchGameState ~ error:', e)
        }
    };

    const fetchGameState = async (nftMintPublickey) => {
        setIsLoading(false)


        if (!program || !nftMintPublickey) return;

        try {
            const game_board = await program.getGameState(nftMintPublickey);
            // document.querySelector('.mask').classList.add('hidden');
            return game_board;
        } catch (e) {
            setFetchError(true);
            console.error('🚀 ~ fetchGameState ~ error:', e)
        }
    };

    const handlePreMint = async () => {
        setIsLoading(true);
        setTimeout(() => {
            handleMint()
        }, 1000)
    }


    const handleMint = async () => {

        if (!program || !publicKey || !connection) {
            setIsLoading(false);
            setWalletPompt(true);
            return;
        }

        // console.log('wallet',wallet);


        if (!isBelongsToTeam) {
            setIsLoading(false);
            setNewUserPompt(true);
            return
        }



        const game = await program.registerGame(
            publicKey,
            season?.seasonId || 0
        );
        // console.log('game',game)

        try {

            const signedTx = await signTransaction(game.transaction);
            // console.log('signedtx', signedTx);
            const sig = await connection.sendRawTransaction(
                signedTx.serialize({
                    verifySignatures: false,
                    requireAllSignatures: false,
                })
            );

            // console.log(sig);

        } catch (error) {
            console.log('Mint new game error: ', error);
        }



        // console.log('game', game);


        setIsLoading(false);


        setTimeout(() => {
            fetchMintState(game.nftMint.publicKey);
        }, 1000)

    }


    const executeMove = (direction, NftPublicKey, team) => {

        // console.log('direction', direction)
        execute(
            NftPublicKey,
            direction,
            team,
            (newBoard, direction) => {
                handleResult(newBoard, direction);
            },
            ({ error, gameOver }) => {
                if (gameOver) {
                    showGameOver();
                } else if (error.indexOf('Identifier("game_board_8192") }, function: 5, instruction: 43, function_name: Some("move_direction") }, 4') > -1) {
                    return;
                } else if (error === "Insufficient gas") {
                    showGasError();
                } else if (error) {
                    showUnknownError(error);
                } else {
                    showUnknownError("Sorry an unknown error occurred. Please try again in a moment.");
                }
            }
        );
    }


    function showGameOver() {
        queue.removeAll();
        addClass(eByClass("error"), "hidden");
        removeClass(eById("error-game-over"), "hidden");
    }

    function showGasError() {
        // queue.removeAll();
        // addClass(eByClass("error"), "hidden");
        // removeClass(eById("error-gas"), "hidden");
    }

    function showUnknownError(error) {
        // queue.removeAll();
        // addClass(eByClass("error"), "hidden");
        // eById("error-unknown-message").innerHTML = error;
        // removeClass(eById("error-unknown"), "hidden");
    }

    function handleResult(newBoard, direction) {
        if (newBoard.topTile > topTile) {
            topTile = newBoard.topTile;

            // console.log('toptile',topTile)
            if (topTile >= 2048) {
                // newBoard.game_over = true;
                // showGameOver()
                setMint2048(true);
            }
            // console.log('new nft');
            // console.log(newBoard.topTile)
            const topTiles = document.getElementsByClassName("top-tile-display");

            // confetti.run();
            document.getElementById('gold').play()


            // setTimeout(() => {
            // if (topTile < 9) return;
            // if (
            //     topTile >= leaderboard.minTile() &&
            //     newBoard.score > leaderboard.minScore()
            // ) {
            // modal.open("climbing-leaderboard", "container");
            // modal.open("high-score", "container");
            // } else {
            // modal.open("top-tile", "container");
            // }
            // }, 1000);
        }

        const tiles = document.getElementsByClassName("tile");
        const resultDiff = board_sonic.diff(
            board_sonic.active().packedSpaces,
            newBoard.packedSpaces,
            direction
        );

        // console.log('resultDiff',resultDiff)

        const scoreDiff = parseInt(newBoard.score) - parseInt(board_sonic.active().score);
        // console.log('score diff');
        // console.log(scoreDiff);
        if (scoreDiff > 0) {
            const scoreDiffElement = document.getElementById("score-diff");
            scoreDiffElement.innerHTML = `+${scoreDiff}`;
            addClass(scoreDiffElement, "floating");
            setTimeout(() => {
                removeClass(scoreDiffElement, "floating");
            }, 2000);
        }

        for (const key of Object.keys(resultDiff)) {
            // console.log('resultDiff', resultDiff);
            const resultItem = resultDiff[key];
            const tile = tiles[parseInt(key)];

            if (resultItem[direction]) {
                const className = `${direction}${resultItem[direction]}`;
                addClass(tile, className);
                setTimeout(() => {
                    removeClass(tile, className);
                }, 500);
            }

            // console.log('resultItem',resultItem);
            if (resultItem.merge) {
                setTimeout(() => {
                    addClass(tile, "merge");

                    setTimeout(() => {
                        removeClass(tile, "merge");
                    }, 500);
                }, 80);
            }
        }

        setTimeout(() => {
            board_sonic.display(newBoard);
        }, 150);
    }


    // const handleTouchMove = (evt, NftPublicKey,team) => {
    //     if (!xDown || !yDown) {
    //         return;
    //     }

    //     var xUp = evt.touches[0].clientX;
    //     var yUp = evt.touches[0].clientY;

    //     var xDiff = xDown - xUp;
    //     var yDiff = yDown - yUp;

    //     if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
    //         if (xDiff > 0) {
    //             executeMove("left", NftPublicKey,team);
    //         } else {
    //             executeMove("right", NftPublicKey,team);
    //         }
    //     } else {
    //         if (yDiff > 0) {
    //             executeMove("up", NftPublicKey,team);
    //         } else {
    //             executeMove("down", NftPublicKey,team);
    //         }
    //     }
    //     /* reset values */
    //     xDown = null;
    //     yDown = null;
    // };

    // const handleTouchStart = (evt) => {
    //     const firstTouch = getTouches(evt);
    //     xDown = firstTouch.clientX;
    //     yDown = firstTouch.clientY;
    // };

    // const getTouches = (evt) => {
    //     console.log('evt',evt)
    //     return evt.touches
    // }

    const initializeKeyListener = (NftPublicKey, team) => {
        // const board = document.getElementById("board");
        // board.addEventListener('touchstart', handleTouchStart(NftPublicKey,team), false);
        // board.addEventListener('touchmove', handleTouchMove(NftPublicKey,team), false);

        window.onkeydown = (e) => {
            // console.log(e.keyCode);
            switch (e.keyCode) {
                case 37:
                case 65:
                    e.preventDefault();
                    executeMove("left", NftPublicKey, team);
                    break;
                case 38:
                case 87:
                    e.preventDefault();
                    executeMove("up", NftPublicKey, team);
                    break;
                case 39:
                case 68:
                    e.preventDefault();
                    executeMove("right", NftPublicKey, team);
                    break;
                case 40:
                case 83:
                    e.preventDefault();
                    executeMove("down", NftPublicKey, team);
                    break;
            }
        };
    }

    async function setActiveGame(game) {
        // console.log('set active game', game);
        window.scrollTo(0, 100);
        if (!game) {
            activeGameAddress = null;
            return;
        }

        addClass(eByClass("error"), "hidden");
        initializeKeyListener(game.address, game.board.team);
        activeGameAddress = game.address;

        reset();

        const activeBoard = board_sonic.convertInfo(game.board);
        topTile = activeBoard.topTile || 2;
        // console.log('activate game');
        board_sonic.display(activeBoard);


    }




    const handleWalletpompt = () => {
        setWalletPompt(false);
    }



    const handleNewUser = () => {
        setNewUserPompt(false);
    }

    const handleNoEnoughKey = () => {
        setNoEnoughKey(false);
    }

    const handleNoEnoughSui = () => {
        setNoEnoughSui(false);
    }


    const handleSoon = () => {
        setShowSoon(false);
    }

    const handlePurchase = () => {
        navigate('/');
    }

    const handlePlay = () => {
        document.getElementById('music').play()
        document.querySelector('.music_img').classList.remove('hidden');
        document.querySelector('.mute_img').classList.add('hidden');
    }

    const handleMute = () => {
        document.getElementById('music').pause()
        document.querySelector('.music_img').classList.add('hidden');
        document.querySelector('.mute_img').classList.remove('hidden');
    }

    const handleLoading = () => {
        // setLoading(false);
    }

    const handleStart = () => {
        window.location.reload()
    }

    const handleMint2048 = () => {
        // setMint2048(false)
    }

    const handleFaucet = () => {
        window.open('https://faucet.sonic.game/#/', '_blank')
    }

    const handleError = () => {
        setFetchError(false);
    }

    const handleSubmit = async () => {
        if (!publicKey || !program) return;

        try {
            const seasonState = await program.getSeasonState(seasonId);
            if (
                !!seasonState
            ) {
                console.log('seasonstate', seasonState.leaderboard.minScore)
                const score_cur = document.getElementById('score').innerText;
                const tile_cur = document.getElementById('top-tile-num').innterText;
                const min_score = seasonState.leaderboard.minScore
                const min_tile = seasonState.leaderboard.minTile
                setMinScore(min_score)
                setMinTile(min_tile)
                // console.log('score_cur', score_cur);
                // console.log('min_score', min_score);
                // console.log('min_tile', min_tile);
                if (score_cur <= min_score) {
                    setShowNotEnoughScore(true);
                }
                else {
                    setShowNotEnoughScore(false);
                    setSubmitLeaderboard(true);

                }

            }
        } catch (e) {
            console.error('failed to get season state', e)
        }
    }

    const handleSubmitLeaderboard = () => {
        // console.log('submit')
        Submit();

    }

    const handleKeepPlaying = () => {
        setSubmitLeaderboard(false);

    }

    const Submit = async () => {
        // console.log('nftMint', nftMint)
        if (!nftMint || !program) return;
        let tx = await program.submitToLeaderBoard(
            publicKey,
            season?.seasonId || 0,
            nftMint
        );



        try {
            tx = await prepareTransaction(tx, connection, publicKey);
            const signedTx = await signTransaction(tx);
            // console.log('signedTx', signedTx)
            const sig = await connection.sendRawTransaction(
                signedTx.serialize()
            );
            // console.log('sig', sig)
            if (sig) {
                setSubmitLeaderboard(false);
                setNftMint(null);

                alert('Submit leaderboard success');


                setTimeout(() => {
                    navigate('/testnet-leaderboard');
                }, 1000)
            }

            setSubmitLeaderboard(false);

            // setTimeout(async () => {
            //     fetchGameState();
            // }, 500);
        } catch (error) {
            alert('Submit leaderboard fail');
        }

    }

    const handleNotEnoughScore = () => {
        setShowNotEnoughScore(false);
    }

    return (
        // <QueryClientProvider client={queryClient}>

        <div className='play_container'>
            <body className='palybody'>
                {/* <Header /> */}
                <div className="header">
                    <div className="header_logo">
                        <img src={logo} alt='Logomark' onClick={handleLogo} className='logomark' />
                    </div>

                    <div className="navigation_bt">
                        <div className="nav nav_purchase" onClick={handleNaviPurchase}>HOME</div>
                        <div className="nav nav_play selected" onClick={handleNaviPlay}>PLAY</div>
                        <div className="nav nav_leaderboard" onClick={handleNaviLeaderboard}>LEADERBOARD</div>
                        <div className='mobile_wallet'>
                            <WalletModalProvider>
                                <WalletMultiButton />
                            </WalletModalProvider>
                        </div>
                    </div>
                </div>

                <div id="modal-overlay" class="hidden">
                    <div class="modal">
                        <div id="close-modal">
                            <div>✕</div>
                        </div>
                        <div id="pause-message" class="message hidden">
                            <p>Just checking :) </p>
                            <h1>Are you still here?</h1>

                            <button id='unpause'>
                                Yes! I want to keep playing
                            </button>
                        </div>
                        <div id="loading-message" class="message">
                            <div class="spinner">
                                <div>
                                    Loading, Please Wait...
                                </div>
                            </div>
                        </div>
                        <div class="during-contest">
                            <div class="get-started-message message text-center hidden">
                                <div class="header">
                                    <div></div>
                                    <div><img src="contest-logo.png" alt="Sui 8192 Logo" /></div>
                                    <div class="close">×</div>
                                </div>
                                <div class="start-content">
                                    <h1 >Sui 8192 Competition</h1>
                                    <p >
                                        10 winning players every day!
                                    </p>
                                    <button class='sign-in' >
                                        Get Started
                                    </button>
                                    <div id="contest-learn-more">
                                        Learn More
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="create-error-message" class="message text-center hidden">
                            <p>There was an error minting a new game.</p>
                            <p id="create-error-error-message"></p>
                        </div>
                        <div id="mint-message" class="message hidden">
                            <div class="subtitle">Mint New Game</div>
                            <p>To start playing you must first mint a new game as an NFT:</p>
                            <div id="mint-game"></div>
                        </div>
                        <div id="top-tile-message" class="message text-center hidden">
                            <div class="subtitle">Congrats!</div>
                            <div class="top-tile-display"></div>
                            <p>
                                You uncovered a new tile!
                                <br />
                                Check it out in your wallet!
                            </p>
                            <br />
                            <a href="https://beta.ethoswallet.xyz/dashboard" target="_blank" class="link-button">Open Wallet Explorer</a>
                            <br /><br />
                            <button class="keep-playing secondary-button">Keep Playing</button>
                        </div>
                        <div id="high-score-message" class="message text-center hidden">
                            <div class="subtitle">Congrats!</div>
                            <div class="top-tile-display"></div>
                            <p>
                                You got a high score!
                            </p>
                            <p>
                                Your game is eligible for the leaderboard!
                            </p>
                            <p>
                                If you submit you can still keep playing.
                            </p>
                            <br />
                            <button id="modal-submit-to-leaderboard">Submit To Leaderboard</button>
                            <br /><br />
                            <button class="keep-playing secondary-button">Keep Playing</button>
                        </div>
                        <div id="climbing-leaderboard-message" class="message text-center hidden">
                            <div class="subtitle">Congrats!</div>
                            <div class="top-tile-display"></div>
                            <p>
                                You're climbing the leaderboard!
                            </p>
                            <p>Take a break and check out your progress (your game is automatically saved):</p>
                            <br />
                            <button id="modal-view-leaderboard">View Leaderboard Position</button>
                            <br /><br />
                            <button class="keep-playing secondary-button">Keep Playing</button>
                        </div>
                    </div>
                </div>
                <div className='topperline'>
                    <div className='topper_season'>Season&nbsp;{seasonId-3}</div>
                    <div className='toppersubline'>
                        <div className='prize_pool_s'>Total Transactions</div>
                        <div className='tx_pp'>&nbsp;{totalTransaction}</div>
                        <div className='countdown_s_sonic'>
                            <div className='countdown_title'>Countdown</div>
                            <div className='countdown_num'>
                                <div className='hours'>{hours}</div>
                                <div>:</div>
                                <div className='minutes'>{minutes}</div>
                                <div>:</div>
                                <div className='seconds'>{seconds}</div>
                            </div>
                        </div>
                        <div className='btc_nft'>Total Users</div>
                        <div className='tx_pp'>&nbsp;{totalUser}</div>
                        <button className='faucet_play_button' onClick={handleFaucet}>Faucet</button>
                    </div>
                </div>
                <div className='play_content'>

                    <div class="instructions topper instruction_mobile">
                        <p>Use the ←→↑↓ keys to combine same tiles together. Unlock the&nbsp;</p>
                        <p class="bitcoin">Bitcoin</p>
                        <p>!</p>
                    </div>

                    <div id="game-links-area" class='signed-in'>
                        <div>
                            <div class="score-box">
                                <div id="score-diff" class="stationary"></div>
                                <div class='score-title'>
                                    SCORE
                                </div>
                                <div id="score">
                                    ---
                                </div>
                            </div>
                            <div class="score-box">
                                <div class='score-title'>
                                    TOP TILE
                                </div>
                                <div id="top-tile-num">
                                    ---
                                </div>
                            </div>
                        </div>
                        <p class="hidden">
                            <button id="submit-game-to-leaderboard" class="secondary-button">
                                Save To Leaderboard
                            </button>
                        </p>
                    </div>
                    <div id="error-game-over" class="error game_over hidden">
                        <div class="game_over_title">Game Over</div>
                        <div class="stats game_over_status">
                            <div class="game_over_score">
                                <div class="subsubtitle game_over_subtitle">Score</div>
                                <div id='score-result' class="score-result tile-result score_color"></div>
                            </div>
                            <div class="game_over_tile">
                                <div class="subsubtitle game_over_subtitle">Top Tile</div>
                                <div id='top-tile' class="tile-result tile-result-box">
                                    <div id='top-tile-value'></div>
                                    <div id='top-tile-name'></div>
                                </div>
                            </div>
                        </div>
                        <br />
                        {/* <div>
                            <button class="secondary-button contest-leaderboard-button during-contest">
                                View Leaderboard
                            </button>
                            <button class="secondary-button leaderboard-button after-contest">
                                View Leaderboard
                            </button>
                        </div> */}
                    </div>
                    <div id="error-unknown" class="error hidden">
                        <div class="close-error">
                            <div>✕</div>
                        </div>
                        <p>There was an error processing your transaction.</p>
                        <p id="error-unknown-message"></p>
                        <p>
                            Please ensure you are logged into your
                            <br />
                            wallet and have a proper balance.
                        </p>
                        <p>
                            Please contact support@ethoswallet.xyz for help.
                        </p>
                    </div>
                    <div id="error-gas" class="error hidden">
                        <div class="close-error">
                            <div>✕</div>
                        </div>
                        <p>An unknown error occurred.</p>
                        <p>
                            Please check to ensure you have enough gas,<br />
                            refresh this page, and try again.
                        </p>
                        <p>
                            Your game is safely stored on-chain.
                        </p>
                    </div>
                    <div id="error-network" class="error hidden">
                        <div class="close-error">
                            <div>✕</div>
                        </div>
                        <p>You're on the wrong network.</p>
                        <p>
                            Please change to the
                            <span id="network">TestNet</span>
                            network in your wallet.
                        </p>
                        <p>
                            Then refresh this page.
                        </p>
                    </div>
                    <audio src={gold} id="gold"></audio>
                    <div id="board">
                        <div className='mask'>
                            <div className='mask-l1'>Every move is on chain.</div>
                            <div className='mask-l2'>Each game state is saved in a GAME NFT</div>
                            <div className='mask-l3'>You need to mint a game NFT before playing</div>
                            <button className='mask-bt' onClick={handlePreMint}>Mint New Game</button>
                        </div>
                        <div id="queue"></div>
                        <div class="row-1 row">
                            <div class="tile-wrapper"><div class="tile"></div></div>
                            <div class="tile-wrapper"><div class="tile"></div></div>
                            <div class="tile-wrapper"><div class="tile"></div></div>
                            <div class="tile-wrapper"><div class="tile"></div></div>
                        </div>
                        <div class="row-2 row">
                            <div class="tile-wrapper"><div class="tile"></div></div>
                            <div class="tile-wrapper"><div class="tile"></div></div>
                            <div class="tile-wrapper"><div class="tile"></div></div>
                            <div class="tile-wrapper"><div class="tile"></div></div>
                        </div>
                        <div class="row-3 row">
                            <div class="tile-wrapper"><div class="tile"></div></div>
                            <div class="tile-wrapper"><div class="tile"></div></div>
                            <div class="tile-wrapper"><div class="tile"></div></div>
                            <div class="tile-wrapper"><div class="tile"></div></div>
                        </div>
                        <div class="row-4 row">
                            <div class="tile-wrapper"><div class="tile"></div></div>
                            <div class="tile-wrapper"><div class="tile"></div></div>
                            <div class="tile-wrapper"><div class="tile"></div></div>
                            <div class="tile-wrapper"><div class="tile"></div></div>
                        </div>
                    </div>

                    {/* <button className='submit_bt hidden' onClick={handleSubmit}>SUBMIT TO LEADERBOARD</button> */}
                    <div class="footer instructions">
                        <div className='game_instru'>
                            <p class="footerwords">
                                FoMoney is an on-chain game build on Sonic
                            </p>
                            <img src={sonic} className='sonic_play_xslogo' />
                            <p class="footerwords">
                                .
                            </p>
                            <img src={mute} onClick={handlePlay} className='mute_img hidden' />
                            <img src={music} onClick={handleMute} className='music_img' />
                        </div>
                        <audio src={song} id="music" autoplay="autoplay" loop="true" preload="auto"></audio>


                        <p class="highlight">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4032 12.6523C17.353 12.1487 18 11.1499 18 10C18 8.85007 17.353 7.85126 16.4032 7.34771C16.7188 6.32002 16.47 5.15625 15.6569 4.34312C14.8437 3.53 13.68 3.28122 12.6523 3.59679C12.1487 2.64698 11.1499 2 10 2C8.85007 2 7.85125 2.64699 7.3477 3.59681C6.32002 3.28126 5.15627 3.53004 4.34315 4.34316C3.53003 5.15628 3.28125 6.32003 3.5968 7.34771C2.64699 7.85126 2 8.85007 2 10C2 11.1499 2.64699 12.1487 3.59681 12.6523C3.28124 13.68 3.53001 14.8437 4.34314 15.6569C5.15627 16.47 6.32003 16.7188 7.34771 16.4032C7.85126 17.353 8.85007 18 10 18C11.1499 18 12.1488 17.353 12.6523 16.4032C13.68 16.7187 14.8437 16.47 15.6569 15.6568C16.47 14.8437 16.7188 13.68 16.4032 12.6523ZM13.8566 8.19113C14.1002 7.85614 14.0261 7.38708 13.6911 7.14345C13.3561 6.89982 12.8871 6.97388 12.6434 7.30887L9.15969 12.099L7.28033 10.2197C6.98744 9.92678 6.51256 9.92678 6.21967 10.2197C5.92678 10.5126 5.92678 10.9874 6.21967 11.2803L8.71967 13.7803C8.87477 13.9354 9.08999 14.0149 9.30867 13.9977C9.52734 13.9805 9.72754 13.8685 9.85655 13.6911L13.8566 8.19113Z" fill="#45B7C8" />
                            </svg>
                            Your achievements are completely verifiable on-chain!
                        </p>

                    </div>
                    <div className='lastline'></div>
                </div>
                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 20,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div class='wallet_pompt_md'>
                        <Modal
                            style={{ top: 200, }}
                            open={submitLeaderboard}
                            onOk={handleKeepPlaying}
                            onCancel={handleKeepPlaying}
                            closeIcon={null}
                            // width={520}
                            width={520}
                            footer={[]}
                        >
                            <div className="connect_wallet mint2048_1">👏 Congrats! 🎉</div>
                            <div className="connect_wallet submit_2">Submit your game to earn 💫Rings!</div>
                            <div className="connect_wallet mint2048_3">Once submitted, the game ends automatically.</div>
                            <div className="connect_wallet mint2048_3">You can submit later to continue playing 🎮.</div>
                            {/* <div className="connect_wallet mint2048_3">After submission, you can reload your game to continue playing.</div> */}
                            <div className='no_enough_key_error'>
                                <button className='confirm_bt' onClick={handleSubmitLeaderboard}>Submit</button>
                                <button className='confirm_bt keep_playing_bt' onClick={handleKeepPlaying}>Keep Playing</button>
                            </div>
                        </Modal>
                    </div>
                </ConfigProvider>

                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 20,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div class='wallet_pompt_md'>
                        <Modal
                            style={{ top: 20, }}
                            open={walletPompt}
                            onOk={handleWalletpompt}
                            onCancel={handleWalletpompt}
                            closeIcon={null}
                            width={280}
                            footer={[]}
                        >
                            <div className="connect_wallet">Connect Wallet First!</div>
                        </Modal>
                    </div>
                </ConfigProvider>

                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 20,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div class='wallet_pompt_md'>
                        <Modal
                            style={{ top: 180, }}
                            open={noEnoughSui}
                            onOk={handleNoEnoughSui}
                            onCancel={handleNoEnoughSui}
                            closeIcon={null}
                            width={400}
                            footer={[]}
                        >
                            <div className="no_enough_sui_error_line1">Hint 💡</div>
                            <div className="no_enough_sui_error">Estimated gas fee is 10 SUI for unlocking the BTC Tile.</div>
                            <div className="no_enough_sui_error">Please ensure you have enough SUI before starting!</div>
                        </Modal>
                    </div>
                </ConfigProvider>

                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 20,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div>
                        <Modal
                            style={{ top: 100, }}
                            open={showSoon}
                            onOk={handleSoon}
                            onCancel={handleSoon}
                            closeIcon={null}
                            width={280}
                            footer={[]}
                        >
                            <div className="connect_wallet">Coming Soon...</div>
                        </Modal>
                    </div>
                </ConfigProvider>
                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 20,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div>
                        <Modal
                            style={{ top: 100, }}
                            open={fetchError}
                            onOk={handleError}
                            onCancel={handleError}
                            closeIcon={null}
                            width={280}
                            footer={[]}
                        >
                            <div className="connect_wallet">Something went wrong</div>
                            <div className="connect_wallet">please try again later</div>
                        </Modal>
                    </div>
                </ConfigProvider>
                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 20,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div>
                        <Modal
                            style={{ top: 100, }}
                            open={showNotEnoughScore}
                            onOk={handleNotEnoughScore}
                            onCancel={handleNotEnoughScore}
                            closeIcon={null}
                            width={500}
                            footer={[]}
                        >
                            <div className="connect_wallet beatit_1">Lowest tile on leaderboard🔝 is&nbsp;<span className='yellow'>{minTile}</span>.</div>
                            <div className="connect_wallet beatit_2">Lowest score on leaderboard🔝 is&nbsp;<span className='yellow'>{minScore}</span>.</div>
                            <div className="connect_wallet beatit_2">Beat both of them to submit your game!🔥🔥</div>
                            <div className="connect_wallet beatit_3"> &#40;💡Hint: Tiles matter more than score. &#41;</div>
                            <button className='confirm_bt not_enough_score' onClick={handleNotEnoughScore}>Keep Playing</button>
                        </Modal>
                    </div>
                </ConfigProvider>

                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#ffffff',
                        },
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 20,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                            Spin: {
                                contentHeight: 500,
                            }
                        },
                    }}
                >
                    <div class='wallet_pompt_md'>
                        <Modal
                            style={{ top: 200, }}
                            // centered
                            open={isLoading}
                            onOk={handleLoading}
                            onCancel={handleLoading}
                            closeIcon={null}
                            width={200}
                            footer={[]}
                        >
                            <div className='loading_content'>
                                <Spin tip="Loading" className='loading' />
                                {/* {content} */}
                                <div className='loading_text'>Loading...</div>
                            </div>
                        </Modal>
                    </div>
                </ConfigProvider>

                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 20,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div class='wallet_pompt_md'>
                        <Modal
                            style={{ top: 200, }}
                            open={mint2048}
                            onOk={handleMint2048}
                            onCancel={handleMint2048}
                            closeIcon={null}
                            width={420}
                            footer={[]}
                        >
                            <div className="connect_wallet mint2048_1">👏 Congrats! 🎉</div>
                            <div className="connect_wallet mint2048_2">You unlocked the BITCOIN tile!</div>
                            <div className="connect_wallet mint2048_3">Submit your game to earn 💫Rings!</div>
                            <button className='confirm_bt get_2048' onClick={handleSubmitLeaderboard}>Submit</button>
                        </Modal>
                    </div>
                </ConfigProvider>

                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 20,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div class='wallet_pompt_md'>
                        <Modal
                            style={{ top: 200, }}
                            open={newUserPompt}
                            onOk={handleNewUser}
                            onCancel={handleNewUser}
                            closeIcon={null}
                            width={330}
                            footer={[]}
                        >
                            <div className="connect_wallet select_tm">Please select team first!</div>
                            <button className='confirm_bt select_tm_bt' onClick={handlePurchase}>Select Team</button>

                        </Modal>
                    </div>
                </ConfigProvider>

                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 20,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div class='wallet_pompt_md'>
                        <Modal
                            style={{ top: 200, }}
                            open={noEnoughKey}
                            onOk={handleNoEnoughKey}
                            onCancel={handleNoEnoughKey}
                            closeIcon={null}
                            width={420}
                            footer={[]}
                        >
                            <div className="connect_wallet no_enough_keys">Sorry, you don't have enough keys</div>
                            {/* <div className="connect_wallet">Get more keys to mint more games</div> */}
                            <div className='no_enough_key_error'>
                                <button className='buy_more_keys confirm_bt' onClick={handlePurchase}>Purchase Keys</button>
                                <button className='leaderboard_bt confirm_bt' onClick={handleNaviLeaderboard}>Leaderboard</button>
                            </div>
                        </Modal>
                    </div>
                </ConfigProvider>

                <div className="media">
                    {/* <img src={mail} alt='mail' className='mail' onClick={handleMail} /> */}
                    <img src={twi} alt='twi' className='twi' onClick={handleTwi} />
                    <img src={gitbook} alt='gitbook' className='gitbook' onClick={handleGit} />
                    <img src={tele} alt='tele' className='tele' onClick={handleTel} />
                    <img src={discord} alt='discord' className='discord' onClick={handleDis} />
                    <img src={med} alt='med' className='med' onClick={handleMed} />
                </div>
                <div className='copyright'>© 2024 FoMoney.io</div>
                <div className="bottom"></div>
            </body>
        </div>
        // </QueryClientProvider>

    );
}


export default PlayPageSonic;
