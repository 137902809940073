/* global BigInt */
import { eById, eByClass, addClass, removeClass, isReverse, isVertical } from "./utils";
// const { default: BigNumber } = require("bignumber.js");


const ROWS = 4;
const COLUMNS = 4;

let active;

const spaceAt = (packedSpaces, row, column) => Number((BigInt(packedSpaces) >> BigInt((row * COLUMNS + column) * ROWS)) & BigInt(0xF));



let coin;


export default {
  ROWS,
  COLUMNS,

  active: () => active,

  spaceAt,

  display: (board) => {
    // console.log('board',board);
    // console.log(board.lastTile);
    if (board.team === "ChainTeam") {
      // console.log('hi chain');
      coin = 'sonic_chain';
    } else if (board.team === "MemeTeam") {
      coin = 'sonic_me';
    }
    const { packedSpaces } = board;
    // console.log('packedSpaces',packedSpaces);

    const allColors = [2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048].map(i => `${coin}${i}`);
    const tiles = eByClass('tile');
    let topTile = 1;
    for (let i = 0; i < ROWS; ++i) {
      for (let j = 0; j < COLUMNS; ++j) {
        const tile = packedSpaces[i][j];
        // console.log('tile',tile);
        if (tile > topTile) {
          topTile = tile;
        }
        // console.log(tile);
        const tileElement = tiles[(i * ROWS) + j];
        removeClass(tileElement, allColors);
        if (tile === 0) {
          tileElement.innerHTML = ""
        } else {
          if (board.lastTile && i == board.lastTile[0] && j == board.lastTile[1]) {
            addClass(tileElement, "appear")
            setTimeout(() => {
              removeClass(tileElement, "appear");
            }, 300);
          }
          addClass(tileElement, `${coin}${tile}`)
          // console.log('add chain img',coin)
          // console.log('add tile',tile)
          tileElement.innerHTML = `<div class='number${tile}'>${tile}</div>`;

        }
      }
    }
    // console.log('board',board);


    const scoreElement = eById('score');
    scoreElement.innerHTML = board.score;
    eById('score-result').innerHTML = board.score;

    const tileElement = eById('top-tile-num');
    tileElement.innerHTML = board.topTile;

    const topTileElement = eById('top-tile');
    removeClass(topTileElement, allColors);
    addClass(topTileElement, `${coin}${topTile}`);


    if (board.gameOver || topTile == 11) {
      removeClass(eById('error-game-over'), 'hidden');
      const final_box = eByClass("tile-result-box")
      addClass(final_box, `${coin}${topTile}`)
    }

    active = board;
  },

  clear: () => {
    const tiles = eByClass('tile');
    for (const tile of tiles) {
      tile.innerHTML = "";
    }
  },

  diff: (packedSpaces1, packedSpaces2, direction) => {
    // console.log('packedSpaces1',packedSpaces1)
    // console.log('packedSpaces2',packedSpaces2)
    // console.log('direction',direction);
    const reverse = isReverse(direction);
    const vertical = isVertical(direction);


    const tiles = {}

    const start = reverse ? COLUMNS - 1 : 0;
    const end = reverse ? 0 : COLUMNS - 1;
    const increment = reverse ? -1 : 1;


    for (let i = start; reverse ? i >= end : i <= end; i += increment) {

      for (let j = start; reverse ? j >= end : j <= end; j += increment) {

        let tile1 = packedSpaces1[i][j];
        const tile2 = packedSpaces2[i][j];
        const index = (i * COLUMNS) + j;



        if (tile2 !== 0) {
          if (tile1 === tile2) continue;

          const searchStart = (vertical ? i : j) + increment;

          for (let x = searchStart; reverse ? x >= end : x <= end; x += increment) {

            const distance = Math.abs(vertical ? x - i : x - j);
            const nextTile = vertical ? packedSpaces1[x][j] : packedSpaces1[i][x];


            if (nextTile === 0) continue;


            const tile1Index = vertical ? (x * COLUMNS) + j : (i * COLUMNS) + x;

            tiles[tile1Index] = {
              [direction]: distance
            }

            if (nextTile === tile2 / 2) {
              // console.log('merge');
              tiles[index] = {
                merge: true
              }

              if (tile1 === null) {
                x = (vertical ? i : j) + increment;
                tile1 = tile2 - 1;
                continue;
              }
            }
            break;
          }
        }
      }
    }

    return tiles;
  },

  convertInfo: (board) => {
    const {
      packed_spaces: packedSpaces,
      last_tile: lastTile,
      top_tile: topTile,
      score,
      team,
      game_over: gameOver,
    } = board;
    return {
      packedSpaces,
      lastTile,
      topTile: Number(topTile),
      score: Number(score),
      team: team,
      gameOver,
    }
  }
}
