'use client';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import * as idl from '../anchor/build/fomolove_game.json';
import { FomoloveGame } from '../anchor/build/fomolove_game.ts';
import { ConfigData, FomoloveGameProgram, SeasonData, TeamData, WinnerData } from '../anchor/fomolove-sonic-program-frontier.ts';
import { useQuery } from '@tanstack/react-query';

interface Ctx {
    configData: ConfigData | undefined;
    chainTeamData: TeamData | undefined;
    memeTeamData: TeamData | undefined;
    fomoloveSonicProgram: FomoloveGameProgram | null;
    season: SeasonData | null;
    fetchSeason: () => void;
    fetchWinnerState: () => Promise<WinnerData | undefined>; // Add fetchWinnerState to context
}

const AnchorContext = createContext<Ctx>({
    configData: undefined,
    chainTeamData: undefined,
    memeTeamData: undefined,
    fomoloveSonicProgram: null,
    season: null,
    fetchSeason: () => {},
    fetchWinnerState: async () => undefined, // Provide default implementation
});

const AnchorContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { connection } = useConnection();
    const { publicKey } = useWallet();
    const [season, setSeason] = useState<SeasonData | null>(null);
    const [winnerState, setWinnerState] = useState<WinnerData | null>(null);

    const fomoloveSonicProgram = useMemo(() => {
        return new FomoloveGameProgram(idl as FomoloveGame, connection);
    }, [connection]);

    const { data: configData } = useQuery({
        queryKey: ['getConfigSate'],
        queryFn: async () => {
            return await fomoloveSonicProgram?.getConfigState();
        },
        enabled: !!fomoloveSonicProgram,
    });

    const { data: chainTeamData } = useQuery({
        queryKey: ['getChainTeamState'],
        queryFn: async () => {
            return await fomoloveSonicProgram?.getChainTeamState();
        },
        enabled: !!fomoloveSonicProgram,
    });

    const { data: memeTeamData } = useQuery({
        queryKey: ['getMemeTeamState'],
        queryFn: async () => {
            return await fomoloveSonicProgram?.getMemeTeamState();
        },
        enabled: !!fomoloveSonicProgram,
    });

    useEffect(() => {
        if (configData?.currentSeasonId) {
            // console.log('test')
            fetchSeason();
        }
    }, [configData]);

    const fetchSeason = async () => {
        if (configData?.currentSeasonId) {
            const season = await fomoloveSonicProgram.getSeasonState(configData.currentSeasonId);
            console.log('season',season)
            setSeason(season);
        }
    };

    const fetchWinnerState = async () => {
        if (!publicKey || !fomoloveSonicProgram) return;
        try {
            const winnerAccount = await fomoloveSonicProgram.getWinnerState();
            return winnerAccount;
        } catch (error) {
            console.log('🚀 ~ fetchWinnerState ~ error:', error);
        }
    };

    return (
        <AnchorContext.Provider
            value={{
                configData,
                chainTeamData,
                memeTeamData,
                fomoloveSonicProgram,
                season,
                fetchSeason,
                fetchWinnerState,
            }}
        >
            {children}
        </AnchorContext.Provider>
    );
};

export const useAnchorContextFrontier = () => {
    return useContext(AnchorContext);
};

export default AnchorContextProvider;
