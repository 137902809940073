import { Connection, PublicKey, Transaction } from "@solana/web3.js";
import { ETeamType } from "../anchor/fomolove-sonic-program-frontier.ts";


export async function prepareTransaction(tx: Transaction, connection: Connection, feePayer: PublicKey): Promise<Transaction> {
    const latestBlockHash = await connection.getLatestBlockhash();
    tx.recentBlockhash = latestBlockHash.blockhash;
    tx.feePayer = feePayer;
    return tx
}


export function mapBTCNFT(topTile: number, team: ETeamType) {

    if (team == ETeamType.Chain) {
        if (topTile == 2) {
            return "https://i.postimg.cc/YCPCNrpP/chain1.png"
        } else if (topTile == 4) {
            return "https://i.postimg.cc/s2ZDwkxg/chain2.png"
        } else if (topTile == 8) {
            return "https://i.postimg.cc/tJDXsyS2/chain3.png"
        } else if (topTile == 16) {
            return "https://i.postimg.cc/tJ9qR75s/chain4.png"
        } else if (topTile == 32) {
            return "https://i.postimg.cc/HxwWCbQG/chain5.png"
        } else if (topTile == 64) {
            return "https://i.postimg.cc/1thRTvVc/chain6.png"
        } else if (topTile == 128) {
            return "https://i.postimg.cc/ry9ynCVx/chain7.png"
        } else if (topTile == 256) {
            return "https://i.postimg.cc/4NNfr7yM/chain8.png"
        } else if (topTile == 512) {
            return "https://i.postimg.cc/VLGf1fbN/chain9.png"
        } else if (topTile == 1024) {
            return "https://i.postimg.cc/YCvpC51z/chain10.png"
        } else if (topTile == 2048) {
            return "https://i.postimg.cc/Gm6b8XKw/chain11.png"
        } else if (topTile == 4096) {
            return "https://i.postimg.cc/tgPphFv1/chain12.png"
        } else if (topTile == 8192) {
            return "https://i.postimg.cc/zBr5p34x/chain13.png"
        } else if (topTile == 16384) {
            return "https://i.postimg.cc/rmNTHMkM/chain14.png"
        } else if (topTile == 32768) {
            return "https://i.postimg.cc/wMxz8ZqJ/chain15.png"
        }
    } else {
        if (topTile == 2) {
            return "https://i.postimg.cc/3r97C81Z/meme1.png"
        } else if (topTile == 4) {
            return "https://i.postimg.cc/3JcTVtP4/meme2.png"
        } else if (topTile == 8) {
            return "https://i.postimg.cc/vZxssWsP/meme3.png"
        } else if (topTile == 16) {
            return "https://i.postimg.cc/KYnhKx4B/meme4.png"
        } else if (topTile == 32) {
            return "https://i.postimg.cc/LX2KLwZ7/meme5.png"
        } else if (topTile == 64) {
            return "https://i.postimg.cc/tTk05k3g/meme6.png"
        } else if (topTile == 128) {
            return "https://i.postimg.cc/1X3kYFBr/meme7.png"
        } else if (topTile == 256) {
            return "https://i.postimg.cc/Bb7rWvw9/meme8.png"
        } else if (topTile == 512) {
            return "https://i.postimg.cc/9Xx5wxX8/meme9.png"
        } else if (topTile == 1024) {
            return "https://i.postimg.cc/jdLVHzLs/meme10.png"
        } else if (topTile == 2048) {
            return "https://i.postimg.cc/Gm6b8XKw/meme11.png"
        } else if (topTile == 4096) {
            return "https://i.postimg.cc/MTCs1j4G/meme12.png"
        } else if (topTile == 8192) {
            return "https://i.postimg.cc/kGWTsg3v/meme13.png"
        } else if (topTile == 16384) {
            return "https://i.postimg.cc/9X9gCJRw/meme14.png"
        } else if (topTile == 32768) {
            return "https://i.postimg.cc/DyKjZLLj/meme15.png"
        }
    }

}
