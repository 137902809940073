import './style.css';
import logo from '../assets/images/logo.png';
import bluesui from '../assets/images/bluesui.png'
import v from '../assets/images/v.png';
import s from '../assets/images/s.png';
import bluev from '../assets/images/bluev.png';
import yellows from '../assets/images/yellows.png';
import pillblue from '../assets/images/pillblue.png';
import pillred from '../assets/images/pillred.png';
import pills from '../assets/images/pills.png';
import discord from '../assets/images/discord2.png';
import tele from '../assets/images/tele2.png';
import twi from '../assets/images/twi2.png';
import med from '../assets/images/med2.png';
import chain from '../assets/images/chain.png';
import meme from '../assets/images/meme.png';
import battle from '../assets/images/battle.png';
import gitbook from '../assets/images/GitBook.png';
import mint from '../assets/images/mint.png';
import sonic from '../assets/images/sonic-logo.png';
import ton from '../assets/images/ton_symbol.png';
import nightly from '../assets/images/nightly.png';
import nightly2 from '../assets/images/nightly2.png';
import milestone from '../assets/images/milestone.png'
import sonic_rules_1 from '../assets/images/sonic_rules_1.png';
import ring from '../assets/images/Rings.png';
import { useState, useEffect, useCallback } from 'react';
import { useAsyncError, useNavigate } from 'react-router-dom';
import { Modal, ConfigProvider, Alert, Flex, Spin, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { toast } from "sonner";
import { TOKEN_2022_PROGRAM_ID, getTokenMetadata } from '@solana/spl-token';
import { PublicKey } from '@solana/web3.js';
import { Analytics } from '@vercel/analytics/react';


import {
    WalletModalProvider,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import dayjs from 'dayjs';
import { prepareTransaction } from '../shared/utils/solana.utils.ts';
const { useAnchorContextFrontier } = require('../shared/provider/AnchorProvider_frontier.tsx');


function HomePageSonicFrontier() {
    const { connection } = useConnection();
    const [openNavi, setOpenVavi] = useState(false);
    const [openRules, setOpenRules] = useState(false);
    const [selectTeam, setSelectTeam] = useState('');
    const [userTeam, setUserTeam] = useState('')
    const { publicKey, sendTransaction, signTransaction, wallet } = useWallet();
    const [walletPompt, setWalletPompt] = useState(false);
    const [teamWarning, setTeamWarning] = useState(false);
    const [blueConfirm, setBlueConfirm] = useState(false);
    const [redConfirm, setRedConfirm] = useState(false);
    const [gameCount, setGameCount] = useState(0);
    const [bestScore, setBestScore] = useState(0);
    const [playerID, setPlayerID] = useState(0);
    const [teamLogo, setTeamLogo] = useState();
    const [gotKeys, setGotKeys] = useState(false);
    const [showSoon, setShowSoon] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [winnerPrize, setWinnerPrize] = useState(0);
    const [seasonId, setSeasonId] = useState(4);
    const [hours, setHours] = useState("24")
    const [minutes, setMinutes] = useState("00")
    const [seconds, setSeconds] = useState("00")
    const [winnerAddress, setWinnerAddress] = useState('');
    const [totalGain, setTotalGain] = useState(0);
    const [showFaucet, setShowFaucet] = useState(false);
    const [userState, setUserState] = useState(null);
    const [isBelongsToTeam, setIsBelongsToTeam] = useState(false);
    const [btcTiles, setBtcTiles] = useState(0);
    const [chainTeam, setChainTeam] = useState('882k');
    const [memeTeam, setMemeTeam] = useState('84k')
    const [totalTransaction, setTotalTransaction] = useState('200+ Million')
    const { default: BigNumber } = require("bignumber.js");
    const navigate = useNavigate();
    const handleShowSoon = () => {
        setShowSoon(true);
    }


    const { season, fomoloveSonicProgram: program } = useAnchorContextFrontier();
    // console.log('season', season);



    const items = [
        {
            key: '1',
            label: (
                <div className='chain_menu_line'>
                    <img src={sonic} className='sonic_menu' />
                     <a className='multi-sonic' target="_parent" rel="noopener noreferrer" href="https://fomoney-sonic.io/">
                    {/* <a className='multi-sonic' target="_parent" rel="noopener noreferrer" onClick={handleShowSoon}> */}
                        Sonic Origin - Devnet
                    </a>

                </div>
            ),
        },
        {
            key: '2',
            label: (
                <div className='chain_menu_line'>
                    <img src={bluesui} className='sui_logo_menu' />
                    <a className='multi-sui' target="_parent" rel="noopener noreferrer" href="https://fomoney.vercel.app/">
                        SUI
                    </a>

                </div>
            ),
        },
        {
            key: '3',
            label: (
                <div className='chain_menu_line'>
                    <img src={ton} className='ton_menu' />
                    {/* <a className="multi-ton" target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com"> */}
                    <a className="multi-ton" target="_blank" rel="noopener noreferrer" href="https://t.me/fomoney_io_bot">
                        TON
                    </a>
                </div>
            ),
        },
    ];


    const team_blue = 1;
    const team_red = 2;

    const [showModal, setShowModal] = useState(false);
    // console.log(wallet);





    const season_info = () => {
        // console.log('program',program)
        // console.log('season', season);

        if (!season) return;


        setSeasonId(season.seasonId)



        const now = dayjs().unix();
        const endedAt = season.endedAt.toNumber();
        // console.log('endedat',endedAt);
        const difference = endedAt - now;

        if (difference <= 0) {
            setHours('00');
            setMinutes('00');
            setSeconds('00');
        } else {
            let hours = Math.floor((difference % (60 * 60 * 24)) / (60 * 60));
            let minutes = Math.floor((difference % (60 * 60)) / 60);
            let seconds = Math.floor(difference % 60);

            hours = `${hours < 10 ? 0 : ''}${hours}`;
            minutes = `${minutes < 10 ? 0 : ''}${minutes}`;
            seconds = `${seconds < 10 ? 0 : ''}${seconds}`;
            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);
        }

        setTimeout(() => season_info(), 1000);
    }

    const detectUserSeasonState = async () => {
        if (!publicKey || !program) return;

        // console.log('hello');
        // console.log('seasonId',seasonId)
        try {
            const userSeasonInfo = await program.getUserSeasonState(publicKey, seasonId);
            // console.log('userSeasonInfo', userSeasonInfo);
            if (
                !!userSeasonInfo
            ) {

                console.log('userSeasonInfo',userSeasonInfo)
                setGameCount(userSeasonInfo.gamePlayed.toString());
                setBestScore(userSeasonInfo.hightestScore);
            }
        } catch (e) {
            console.error('Fatch user season state failed', e);
        }
    }

    // const fetchNFTS = async () => {
    //     if (!publicKey){
    //         return;
    //     }
    //     const token2022Accounts =
    //         await connection.getParsedTokenAccountsByOwner(publicKey, {
    //             programId: TOKEN_2022_PROGRAM_ID,
    //         });

    //     const _nfts = [];
    //     // console.log('_nfts',_nfts)

    //     try {
    //         for (let nft of token2022Accounts.value) {
    //             const publibKey = new PublicKey(nft.account.data.parsed.info.mint);
    //             const nftMedatadata = await getTokenMetadata(
    //                 connection,
    //                 publibKey,
    //                 'confirmed'
    //             );

    //             if (
    //                 nftMedatadata &&
    //                 program?.configPDA &&
    //                 nftMedatadata.updateAuthority?.equals(program?.configPDA)
    //             ) {
    //                 _nfts.push(nftMedatadata);
    //             }
    //         }
    //         // console.log('_nfts', _nfts);

    //         if (!_nfts) {
    //             return;
    //         }

    //         let count = 0;
    //         const nft_object = _nfts?.map((object) => {
    //             // console.log('object', object.uri);


    //             const logo = object.uri?.split('/').pop().split('.')[0];
    //             let index = logo.indexOf('me');

    //             let tile = index === 0 ? Number(logo.slice(2)) : Number(logo.slice(5));

    //             tile = Math.pow(2, tile);
    //             if (tile == 2048) {
    //                 count += 1;
    //             }

    //             // console.log('team', team);
    //             // console.log('tile', tile);

    //             return {
    //                 tile: tile,
    //             }
    //         }).sort((a, b) => {
    //             return (b.tile - a.tile)
    //         })
    //         setBtcTiles(count)
    //         // console.log('count',count);

    //     } catch (e) {
    //         console.error('Fetch NFTs failed', e);
    //     }
    // }

    const fetchMemeTeam = async () => {
        if (program) {
            try {
                let memeTeamAccount = await program.getMemeTeamState();
                setMemeTeam(memeTeamAccount.numPlayers)
            } catch (e) {
                console.error('fatch meme team failed', e)
            }
        }
    }

    const fetchChainTeam = async () => {
        if (program) {
            try {
                let chainTeamAccount = await program.getChainTeamState();
                setChainTeam(chainTeamAccount.numPlayers)
            } catch (e) {
                console.error('fatch chain team failed', e)
            }
        }
    }



    const tx_info = async () => {
        if (program) {
            console.log('hi data')
            try {
                const number = await program.getNumberOfTransaction();
                // console.log('number',number)
                const tx_str = number.toString();
                setTotalTransaction(tx_str);
                console.log('total tx', number);
            } catch (e) {
                console.error('fatch total tx failed', e)
            }

        }
    }

    useEffect(() => {
        season_info();
        // tx_info();
        // fetchMemeTeam();
        // fetchChainTeam();
        if (publicKey) {
            // console.log('yes detectuser')
            detectUserTeam();
            detectUserSeasonState();
        }
    }, [publicKey, season?.seasonId])

    const handleLogo = () => {
        navigate('/');
    }

    const handleNaviPurchase = () => {
        navigate('/');
        let purchase = document.getElementById('team_frame');
        purchase.scrollIntoView();
    }

    const handleNaviPlay = () => {
        document.querySelector('.nav_purchase').classList.remove('selected');
        document.querySelector('.nav_play').classList.add('selected');
        navigate('/testnet-play');
    }

    const handleNaviLeaderboard = () => {
        document.querySelector('.nav_purchase').classList.remove('selected');
        document.querySelector('.nav_leaderboard').classList.add('selected');
        navigate('/testnet-leaderboard');
    }


    const handleRule = () => {
        setOpenRules(true);
    }

    const handleRulesOk = () => {
        setOpenRules(false);
    }

    const handleRulesCancel = () => {
        setOpenRules(false);
    }

    const handleDis = () => {
        window.open('https://discord.com/invite/HP6cBgaP9w', '_blank');
    }

    const handleTel = () => {
        window.open('https://t.me/fomoneyofficial', '_blank');
    }

    const handleTwi = () => {
        window.open('https://twitter.com/Fomoney2048', '_blank');
    }

    const handleMed = () => {
        window.open('https://medium.com/@Fomoney2048', '_blank');
    }

    const handleGit = () => {
        window.open('https://fomoney.gitbook.io/fomoney-litepaper', '_blank');
    }

    const handleClaimRings = () => {
        window.open('https://odyssey.sonic.game/task/milestone', '_blank');
    }

    const selectBlue = () => {
        // console.log('connection',connection);
        // console.log('wallet',wallet);
        // console.log('publickey',publicKey)
        if (!wallet || !publicKey) {
            setWalletPompt(true);
            return;
        }
        if (userTeam) {
            return;
        }
        document.querySelector('.pillred_2').classList.remove('selected');
        document.querySelector('.pillblue_2').classList.add('selected');
        setSelectTeam('ChainTeam');
    }



    const selectRed = () => {
        if (!wallet || !publicKey) {
            setWalletPompt(true);
            return;
        }
        if (userTeam) {
            return;
        }
        document.querySelector('.pillblue_2').classList.remove('selected');
        document.querySelector('.pillred_2').classList.add('selected');
        setSelectTeam('MemeTeam');
    }


    const handleGo = () => {
        if (!wallet || !publicKey) {
            setWalletPompt(true);
            return;
        }
        if (!selectTeam) {
            setTeamWarning(true);
            return;
        }
        if (selectTeam == 'ChainTeam') {
            setBlueConfirm(true);
            setTeamLogo(pillblue);

        } else if (selectTeam == 'MemeTeam') {
            setRedConfirm(true);
            setTeamLogo(pillred);
        }
        return;
    }

    const handleWalletpompt = () => {
        setWalletPompt(false);
    }

    const handleTeamWarning = () => {
        setTeamWarning(false);
    }

    const handleRedConfirm = () => {
        setRedConfirm(false);
    }

    const handleBlueConfirm = () => {
        setBlueConfirm(false);
    }

    const detectUserTeam = async () => {
        if (!publicKey || !program) return;

        // console.log('hello');
        // console.log('publicKey',publicKey);
        // console.log('program',program);
        try {
            const userAccount = await program.getUserState(publicKey);
            if (
                !!userAccount
            ) {
                setIsBelongsToTeam(true);
            }
            userAccount && setUserState(userAccount);
            if (userAccount) {
                // console.log('userAccount',userAccount)
                setUserTeam(userAccount.team)
                if (userAccount.team.chainTeam) {
                    // console.log('yes chainteam');
                    setTeamLogo(pillblue);
                    setSelectTeam('ChainTeam');
                    document.querySelector('.pillred_2').classList.remove('selected');
                    document.querySelector('.pillblue_2').classList.add('selected');

                } else {
                    setTeamLogo(pillred);
                    setSelectTeam('MemeTeam');
                    document.querySelector('.pillblue_2').classList.remove('selected');
                    document.querySelector('.pillred_2').classList.add('selected');
                }
                document.querySelector('.sonic_teamlogo').classList.remove('hidden');
                document.querySelector('.purchasse_middle_button_go').classList.add('hidden');
                document.querySelector('.purchasse_middle_button_play').classList.remove('hidden');

            }
            return userAccount;
        } catch (error) {
            console.log('🚀 ~ detectUser ~ error:', error);
        }


    };

    // console.log('teamlogo',{teamLogo});
    const handleBet = async () => {
        setBlueConfirm(false);
        setRedConfirm(false);

        setIsLoading(true);

        let tx = await program.chooseTeam(
            publicKey,
            selectTeam
        );

        tx = await prepareTransaction(tx, connection, publicKey);
        // console.log('tx',tx)

        const signedTx = await signTransaction(tx);
        // console.log('signedTx',signedTx)



        try {
            const sig = await connection.sendRawTransaction(signedTx.serialize())
            toast.success("Transaction send!", {
                action: {
                    label: "Show Transaction",
                    onClick: () => {
                        window.open(
                            `https://explorer.solana.com/tx/${sig}`,
                            "_blank"
                        );
                    },
                },
            });
            setTimeout(() => {
                detectUserTeam();
            }, 3000);
            document.querySelector('.sonic_teamlogo').classList.remove('hidden');

        } catch (error) {
        }

        setIsLoading(false);


    }




    const handleMintPlay = () => {
        navigate('/testnet-play');
        window.scrollTo({
            top: 0,
        });
    }

    const handleBackKey = () => {
        setGotKeys(false);
    }




    const handleSoon = () => {
        setShowSoon(false);
    }

    const handleLoading = () => {
        // setLoading(false);
    }





    const handleFaucetCancel = async () => {
        setShowFaucet(false);
    }


    const handleTgBot = () => {
        window.open('https://t.me/fomoney_io_bot', '_blank');

    }





    return (

        <div className='container'>

            <body className='homebody'>
                {/* <Header /> */}
                <div className="header">
                    <div className="header_logo">
                        <img src={logo} alt='Logomark' onClickLogo={handleLogo} className='logomark' />
                    </div>

                    <div className="navigation_bt">
                        <ConfigProvider
                            theme={{
                            }}
                        >
                            <Dropdown
                                menu={{
                                    items,
                                    theme: "dark",
                                }}
                            >
                                <a onClick={(e) => e.preventDefault()} className='multi-chain-sonic'>
                                    <Space>
                                        <div className='selected_sui'>
                                            <img src={sonic} className='sonic_logo' />
                                            <div className='multi-sonic'>Sonic Frontier - Testnet</div>
                                        </div>
                                        <DownOutlined />
                                    </Space>
                                </a>
                            </Dropdown>
                        </ConfigProvider>
                        <div className="nav nav_purchase selected" onClick={handleNaviPurchase}>HOME</div>
                        <div className="nav nav_play" onClick={handleNaviPlay}>PLAY</div>
                        <div className="nav nav_leaderboard" onClick={handleNaviLeaderboard}>LEADERBOARD</div>
                        <WalletModalProvider className='wallet_bt_sonic'>
                            <WalletMultiButton  />
                        </WalletModalProvider>
                    </div>
                </div>
                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 20,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div class='wallet_pompt_md'>
                        <Modal
                            style={{ top: 20, }}
                            open={walletPompt}
                            onOk={handleWalletpompt}
                            onCancel={handleWalletpompt}
                            closeIcon={null}
                            width={280}
                            footer={[]}
                        >
                            <div className="connect_wallet">Connect Wallet First!</div>
                        </Modal>
                    </div>
                </ConfigProvider>

                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 20,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div>
                        <Modal
                            style={{ top: 20, }}
                            open={teamWarning}
                            onOk={handleTeamWarning}
                            onCancel={handleTeamWarning}
                            closeIcon={null}
                            width={280}
                            footer={[]}
                        >
                            <div className="connect_wallet">Choose Your Team First!</div>
                        </Modal>
                    </div>
                </ConfigProvider>

                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 20,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div>
                        <Modal
                            style={{ top: 200, }}
                            open={showFaucet}
                            onOk={handleFaucetCancel}
                            onCancel={handleFaucetCancel}
                            closeIcon={null}
                            width={280}
                            footer={[]}
                        >
                            <div className="connect_wallet">You got 1 Sonic! 🎉</div>
                        </Modal>
                    </div>
                </ConfigProvider>

                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 20,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div>
                        <Modal
                            style={{ top: 100, }}
                            open={showSoon}
                            onOk={handleSoon}
                            onCancel={handleSoon}
                            closeIcon={null}
                            width={280}
                            footer={[]}
                        >
                            <div className="connect_wallet">Coming Soon...</div>
                        </Modal>
                    </div>
                </ConfigProvider>

                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 40,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div>
                        <Modal
                            open={blueConfirm}
                            onOk={handleBlueConfirm}
                            onCancel={handleBlueConfirm}
                            closeIcon={null}
                            width={380}
                            footer={[]}
                        >
                            <div className="teampompt">
                                <div className='teampompt_title'>You have chosen </div>
                                <img src={pillblue} className='pillblue_3' />
                                <div>It can't be changed during this season.</div>
                                <div>Are you sure?</div>
                                <div className='team_confirm_bt'>
                                    <button className='lets_bet_bt confirm_bt' onClick={handleBet}>Let's Bet!</button>
                                    <button className='back_bt confirm_bt' onClick={handleBlueConfirm}>Back</button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </ConfigProvider>

                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 40,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div>
                        <Modal
                            open={redConfirm}
                            onOk={handleRedConfirm}
                            onCancel={handleRedConfirm}
                            closeIcon={null}
                            width={380}
                            footer={[]}
                        >
                            <div className="teampompt">
                                <div className='teampompt_title'>You have chosen </div>
                                <img src={pillred} className='pillred_3' />
                                <div>It can't be changed during this season.</div>
                                <div>Are you sure?</div>
                                <div className='team_confirm_bt'>
                                    <button className='lets_bet_bt confirm_bt' onClick={handleBet}>Let's Bet!</button>
                                    <button className='back_bt confirm_bt' onClick={handleRedConfirm}>Back</button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </ConfigProvider>


                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#ffffff',
                        },
                        components: {
                            Modal: {
                                contentBg: '#937ef5',
                                borderRadiusLG: 20,
                                // boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                            Spin: {
                                contentHeight: 500,
                            }
                        },
                    }}
                >
                    <div class='wallet_pompt_md'>
                        <Modal
                            style={{ top: 200, }}
                            // centered
                            open={isLoading}
                            onOk={handleLoading}
                            onCancel={handleLoading}
                            closeIcon={null}
                            width={200}
                            footer={[]}
                        >
                            <div className='loading_content'>
                                <Spin tip="Loading" className='loading' />
                                {/* {content} */}
                                <div className='loading_text'>Loading...</div>
                            </div>
                        </Modal>
                    </div>
                </ConfigProvider>



                <div className='homebase'>
                    <div className='title_season'>SEASON&nbsp;{seasonId-3}</div>
                    <div className='title_prize'>prize pool</div>
                    <div className='prize_pool'>
                        <img src={sonic} className='prize_logo_sonic' />
                        <div className='prize_num_sonic'>1,000,000</div>
                        <img src={ring} className='prize_logo_ring' />
                    </div>
                    <div className='hp_tx_time'>
                        <div className='countdown_hp'>
                            <div className='title_time'>Countdown</div>
                            <div className='count_down'>
                                {/* {countDown} */}
                                {/* <div className='hours_h'>{hours}</div> */}
                                <div className='hours_h'>{hours}</div>
                                <div className='colon_h'>:</div>
                                <div className='minutes_h'>{minutes}</div>
                                <div className='colon_h'>:</div>
                                <div className='seconds_h'>{seconds}</div>
                            </div>
                        </div>
                        <div className='tx_hp'>
                            <div className='tx_title'>Total Transactions</div>
                            <div className='tx_num'>{totalTransaction}</div>
                        </div>
                    </div>
                    <div id='team_frame' className='team_frame'>
                        <div className='team_players'>
                            <div className='chain_in_pot'>CHAIN:&nbsp;{chainTeam}</div>
                        </div>
                        <div className='meme_in_pot'>MEME:&nbsp;{memeTeam}</div>
                    </div>
                </div>
                <div className='rule_parent'>
                    <div className="title_rule" onClick={handleRule} >PLEASE READ RULES CAREFULLY BEFORE PLAYING</div>
                    <button className='claim_button' onClick={handleRule}>RULES</button>
                </div>
                <ConfigProvider
                    theme={{
                        components: {
                            Modal: {
                                contentBg: '#170f23dc',
                                boxShadow: '0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237),0 0 5px rgb(205, 166, 237);'
                            },
                        },
                    }}
                >
                    <div class='md'>
                        <Modal
                            open={openRules}
                            onOk={handleRulesOk}
                            onCancel={handleRulesCancel}
                            closeIcon={null}
                            width={800}
                            footer={[]}
                        >
                            <div className="rules">
                                <div className="rules_title">GAME RULES</div>
                                <div className="firstsentence">Please follow <a href="https://twitter.com/Fomoney2048">Twitter</a> for the upcoming $Sonic Ring rewards!</div>
                                <div className="rules_body">
                                    <div className="rules_line">
                                        <div className="rules_num">1</div>
                                        <div className="rules_text">
                                            Play the game and earn 🎁 Ring Mystery Box and 💫 Sonic Rings based on your transactions. You have the chance to win a share of a Prize Pool of 1 MILLION 💫Sonic Rings if you make it to the Top 10 of the Daily Leaderboard or reach the Bitcoin Tile.                                             <p className="p"></p>
                                        </div>
                                    </div>
                                    <div className="rules_line">
                                        <div className="rules_num">2</div>
                                        <div className="rules_text">
                                            Each season spans a 24-hour 🕙 game cycle. It will start every day at 1am UTC. To enter, choose your team - the Blue or Red team. Blue team tiles represent CHAINs, while Red embraces iconic MEMEs.
                                        </div>
                                    </div>
                                    <img className="rules_battle" src={battle} />
                                    <div className="rules_line">
                                        <div className="rules_num">3</div>
                                        <div className="rules_wallet">
                                            Register a <span className='nightly_wl'>Nightly Wallet</span> and switch to Sonic Testnet to support <span className='nightly_wl'>Auto-Confirm</span> functionality. Click Play and mint a Game NFT to record your game state.
                                        </div>
                                    </div>
                                    <div className="rules_key_mint">
                                        <img src={nightly} className="rules_nightly" />
                                        <img src={nightly2} className="rules_nightly" />
                                    </div>
                                    <div className="rules_line">
                                        <div className="rules_num">4</div>
                                        <div className="rules_text">
                                            You can enable the auto-confirm feature to merge identical tile. Each move would be an on-chain transaction. Combine identical tiles using the ←→↑↓ keys  &#40;on PC&#41;, watching them merge into one.
                                        </div>
                                    </div>
                                    {/* <img src={sonic_rules_1} className="rules_sonic_img_1" /> */}
                                    <div className="rules_line">
                                        <div className="rules_num">5</div>
                                        <div className="rules_text">
                                            Your mission? Make it to the Top 10 of the Daily Leaderboard and become the ultimate tile master, combining from humble beginnings to conquer BITCOIN/2048 glory.
                                        </div>
                                    </div>
                                    <div className="rules_games">
                                        <img src={chain} className="rules_game" />
                                        <img src={meme} className="rules_game" />
                                    </div>
                                    <div className="rules_line">
                                        <div className="rules_num">6</div>
                                        <div className="rules_text">
                                            Check your scores and ranking on the Leaderboard.
                                        </div>
                                    </div>
                                    <div className="rules_line">
                                        <div className="rules_num">7</div>
                                        <div className="rules_text">
                                            Don’t forget to claim your 🎁 Mystery Boxes on Tx milestone and open all to claim Sonic Rings!
                                        </div>
                                    </div>
                                    <img src={milestone} className="rules_milestone" />
                                    <div className="sonic_rules_last_line">Learn more about FoMoney on <a href="https://fomoney.gitbook.io/fomoney-litepaper/how-to-play-fomoney-to-earn-1-million-sonic-odyssey-rings-via-odyssey-campaign">Gitbook</a>!</div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </ConfigProvider>

                <div className='view_on_pc'>Kindly access on your PC for gameplay</div>

                <div id='purchase' className='purchase_container_sonic'>
                    <div className='purchase_left_sonic'>
                        <div className='purchase_title purchase_title_side_sonic'>Season&nbsp;{seasonId-3}</div>
                        <div className='purchase_left_1 purchase_left_1_team'>
                            <div>Your Team</div>
                            <img className='sonic_teamlogo hidden' src={teamLogo} />
                        </div>
                        <div className='purchase_left_1'>
                                <div>FoMoney Prize Pool:</div>
                                <div className='purchase_left_2_sonic_rings'>
                                    <div>1M Rings</div>
                                </div>
                        </div>
                        <div className='purchase_left_1'>
                            <div>Your Games:</div>
                            <div className='purchase_left_2_sonic_rings'>{gameCount}</div>
                        </div>
                        {/* <div className='purchase_left_1'>
                            <div>Your Highest Score:</div>
                            <div className='purchase_left_2_sonic_rings'>{bestScore}</div>
                        </div> */}

                    </div>
                    <div className='purchase_middle_1_sonic'>
                        <div className='purchase_title'>CHOOSE YOUR TEAM FIRST</div>
                        <div className='purchase_middle_yellow'>Your Team affects your gameplay</div>
                        <div className='pill_choose_sonic'>
                            <img src={pillblue} onClick={selectBlue} className='pillblue_2'></img>
                            <img src={pillred} onClick={selectRed} className='pillred_2'></img>
                        </div>
                        <div className='team_choose'>
                            <div className='team_name'>CHAIN</div>
                            <div className='team_name'>MEME</div>
                        </div>
                        <button className='purchasse_middle_button_go' onClick={handleGo}>GO</button>
                        <button className='purchasse_middle_button_play hidden' onClick={handleMintPlay}>Play</button>

                    </div>
                    <div className='purchase_right_sonic'>
                        <div className='purchase_title purchase_title_side_sonic'>Your Rewards</div>
                        <div className='purchase_right_top'>
                            <div className='vault vault_line_1'>
                                <div>Total Rings:</div>
                                <button className='claim_rings' onClick={handleClaimRings}>Claim Rings</button>
                            </div>
                            {/* <div className='vault'>
                                <div>Highest Ranking:</div>
                                <div className='sonic_vault_value'>0</div>
                            </div> */}
                            {/* <div className='vault'>
                                <div>Your BTC NFT:</div>
                                <div className='sonic_vault_value'>{btcTiles}</div>
                            </div> */}
                            <div className='vault'>
                                <div>Total Gains:</div>
                                <div className='sonic_vault_value'>Coming Soon</div>
                            </div>
                            <div className='vault'>
                                <div>Quest Bot:</div>
                                <button className='tgbot_bt' onClick={handleTgBot}>Earn 400k Rings</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <button className='lets_bet_bt confirm_bt create_bt' onClick={init}>init</button> */}
                {/* <button className='lets_bet_bt confirm_bt create_bt' onClick={handleSeason}>season id</button> */}
                {/* <button className='lets_bet_bt confirm_bt create_bt' onClick={handleViewID}>viewIdByAdd</button> */}
                {/* <button className='lets_bet_bt confirm_bt create_bt' onClick={handleCreateSeason}>create season</button> */}
                <div className="media">
                    {/* <img src={mail} alt='mail' className='mail' onClick={handleMail} /> */}
                    <img src={twi} alt='twi' className='twi' onClick={handleTwi} />
                    <img src={gitbook} alt='gitbook' className='gitbook' onClick={handleGit} />
                    <img src={tele} alt='tele' className='tele' onClick={handleTel} />
                    <img src={discord} alt='discord' className='discord' onClick={handleDis} />
                    <img src={med} alt='med' className='med' onClick={handleMed} />
                </div>
                <div className='copyright'>© 2024 FoMoney.io</div>
                <div className="bottom"></div>

                <Analytics />
            </body>

        </div>

    );
}


export default HomePageSonicFrontier;
