
module.exports = {
  // SUI
  // contractAddress: "0x40c0b4be4022022000d22bfda1675649d8ac9bb29f56cc2163f498754598450e",
  // global_config: "0x1b2986d6026be4952c07dce92d523e03b67018be9902beb7d3e4bee6532bc301",
  // player_maintainer: "0x3015ca102297d3f83e3859583512a59dd43b43cc89d986532f8c2b3744188162",
  // season: "0x403f1fe60df216f376b5ccc5533bd2949b6454fef365a1ed411d0b8f0c1867e9",
  // season_id: "16"

  // buck
  contractAddress: "0x196b89ebfca9d59455843e7ecb328fc24e2ed16562878c16337e2ebd7fc12a07",
  global_config: "0x1c5a9254e7ea5e764ec51a15078abda5d613637a156046d778094451400c5d50",
  player_maintainer: "0x1d8c20d93836a211e4519a4148e92071c8a7d2e55067de8d6f3c4df9139b3376",
  season: "0x6be6878d9bad908f855eb1426a0a990ea58fc4f9109684dec2977a344a99c9b1",
  season_id: "1",
  coin_type: "0xd5495aa41ed139b8e1b0c9e427490b5c29fca7c0b526b5d8dafa2b0ba7ace3f5::buck::BUCK",
}
